<template>
  <v-app>
    <v-main>
      <router-view />

      <!-- 布局 -->
      <!-- <div class="lime lighten-3">
        <div class="d-flex justify-center">
          <div class="blue" style="width: 100px">左1</div>
          <div class="cyan" style="width: 100px">
            主2
            <div class="deep-purple" style="width: 100px">下1</div>
          </div>
          <div class="pink" style="width: 100px">右3</div>
        </div>

        <div class="d-flex justify-center cyan lighten-1">
          <div class="brown darken-3" style="width: 100px">下2</div>
        </div>
      </div> -->
      
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style scoped>


</style>
