import Vue from "vue";
import App from "./App.vue";

// router
import router from "./router";
import vuetify from "./plugins/vuetify";
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

// // vuex
// import store from "./store";

Vue.config.productionTip = false;


new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
